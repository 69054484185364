<template>
  <div class="order-item" @click="orderDetails">
    <img
      alt=""
      class="shop-banner"
      :src="item.shopBanner || require('@/assets/imgs/banner-home.jpg')"
    />
    <div class="order-name">
      <span>{{ item.pShopName }}</span>
      <img
        alt=""
        class="order-name-swap"
        :src="require('@/assets/imgs/icon_swap@2x.png')"
      />
      <span>{{ item.pGhShopName }}</span>
    </div>
    <div class="order-foot">
      <div class="order-timer">
        <p class="order-timer-t">{{ orderDuration(item) }}</p>
        <span>{{ item.pborrowtime | dateFmt }}</span>
      </div>
      <div class="order-price">
        {{ item.pCurrencyName }} {{ (item.pJiesuan || 0).toFixed(2) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(["locale"]),
    i18n() {
      return this.$t("order_item")
    },
    isZujie() {
      return (
        this.item.pJiesuanid === 0 &&
        (this.item.pZujie === 1 || this.item.pZujie === 4)
      )
    }
  },
  data() {
    return {
      typeMap: {}
    }
  },
  methods: {
    onSubmit() {
      this.$router.push({
        path: "/confirm",
        query: {
          price: this.item.pJiesuan,
          payType: "FK",
          outTradeNo: this.item.pOrderid,
          cabinetID: this.item.pCabinetid
        }
      })
    },
    // 订单详情
    orderDetails() {
      this.$router.push(`/order/${this.item.porderid}`)
    },
    // 格式化时间
    orderDateFmt(seconds = 0) {
      let hours = Number.parseInt(seconds / 3600)
      let minutes = Number.parseInt((seconds - hours * 3600) / 60)
      let second = Number.parseInt(seconds - hours * 3600 - minutes * 60)
      second = second < 10 ? "0" + second : second
      hours = hours < 10 ? "0" + hours : hours
      minutes = minutes < 10 ? "0" + minutes : minutes
      return `${hours}:${minutes}:${second}`
    },
    // 订单时长
    orderDuration(item) {
      let seconds = item.puseMins * 60
      if (item.pZujie === 1) {
        const nT = new Date(this.$dateFmt()).getTime()
        const bT = new Date(item.pborrowtime).getTime()
        seconds = parseInt((nT - bT) / 1000)
      }
      return this.orderDateFmt(seconds)
    }
  },
  created() {
    this.typeMap = {
      0: this.i18n.type_0,
      1: this.i18n.type_1,
      2: this.i18n.type_2,
      3: this.i18n.type_3,
      4: this.i18n.type_4,
      5: this.i18n.type_5
    }
  }
}
</script>

<style lang="less" scoped>
.order-item {
  position: relative;
  padding: 36px;
  border: 2px solid #d5d5d5;
  border-radius: 20px;
  margin-bottom: 32px;
  box-shadow: 0 2px 2px 0 #d5d5d5;
  .shop-banner {
    width: 100%;
    height: 204px;
    object-fit: contain;
  }
  .order-name {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-swap {
      width: 56px;
    }
  }

  .order-foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .order-timer {
      color: rgba(0, 0, 0, 0.5);
      font-size: 28px;
      &-t {
        color: #41200f;
        font-size: 62px;
        margin: 0;
      }
    }
    .order-price {
      padding: 18px 42px;
      border-radius: 16px;
      color: #41200f;
      font-size: 36px;
      background: linear-gradient(269deg, #e3e829 0%, #398e3c 100%);
    }
  }
}
</style>
