<template>
  <div class="collapse-item-warp">
    <div
      class="collapse-item-head"
      :class="{ active: value }"
      @click="onChange"
    >
      <span class="title">
        {{ item.pTitle }}
      </span>
    </div>
    <div class="collapse-item-body" v-show="value">
      {{ item.pContent }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange() {
      this.$emit("input", !this.value)
    }
  }
}
</script>

<style>
.collapse-item-warp {
  margin: 0 52px 20px;
  border: 1px solid #d5d5d5;
  border-radius: 20px;
  background-color: #fff;
}
.collapse-item-warp + .collapse-item-warp {
  /* border-top: 1px solid rgba(217, 217, 217, 1); */
}
.collapse-item-head {
  display: flex;
  align-items: center;
  padding: 30px 34px;
  font-size: 32px;
  color: #41200f;
}
.collapse-item-head.active {
  color: #398e3c;
}
.collapse-item-head span {
  flex: 1;
  padding-right: 8px;
}
.collapse-item-head:after {
  content: "";
  display: inline-block;
  margin: 10px 0 0 0;
  width: 20px;
  height: 20px;
  border-top: 4px solid #cccccc;
  border-right: 4px solid #cccccc;
  transform: rotate(-45deg);
  transition: transform 0.5s;
}
.collapse-item-head.active:after {
  transform: rotate(135deg);
  margin: 0px 0px 10px;
}
.collapse-item-body {
  padding: 20px 40px;
  color: rgba(102, 102, 102, 1);
}
</style>
