<template>
  <div class="google-map">
    <div id="g-map"></div>
    <img
      class="location-icon"
      src="../../assets/imgs/location_icon.png"
      alt=""
    />
    <div class="location">
      <van-image
        :src="require('@/assets/imgs/ic_location.png')"
        width="1rem"
        @click="onLocation"
      ></van-image>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
let gMapContext
let gMapTimer
let marker
let myMarker
let infoWindow
export default {
  props: {
    apikey: {
      type: String,
      default: process.env.VUE_APP_ANDROID_APIKEY
    },
    latLng: {
      type: Array,
      default: () => {
        return []
      }
    },
    locationList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState(["lang"]),
    i18n() {
      return this.$t("map")
    }
  },
  data() {
    return {
      gMapContext: null,
      times: 0,
      latitude: 13.401109,
      longitude: 52.51891,
      centerChange: false
    }
  },
  watch: {
    latLng(val, oldVal) {
      if (val && val.length === 2) {
        this.latitude = val[0]
        this.longitude = val[1]
        if (gMapContext && !this.centerChange) {
          const center = { lat: this.latitude, lng: this.longitude }
          gMapContext.setZoom(16)
          gMapContext.setCenter(center)
        }
      }
    },
    locationList(val) {
      const list = []
      if (val.length) {
        val.map((item, index) => {
          list.push([
            { lat: Number(item.latitude), lng: Number(item.longitude) },
            item
          ])
        })
        this.setMarker(list)
      } else {
        console.log(window.marker)
        if (window.marker) {
          marker.setMap(null)
        }
        this.$toast(this.i18n.result_msg)
      }
    }
  },
  methods: {
    initMap() {
      if (document.getElementById("g-map")) {
        this.times = 0
        console.log("gmapcontext")
        // The location of Uluru
        const center = { lat: this.latitude, lng: this.longitude }
        // The map, centered at Uluru
        gMapContext = new google.maps.Map(document.getElementById("g-map"), {
          zoom: 12,
          center: center,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          clickableIcons: false
        })
        // console.log('center', center)
        gMapContext.setCenter(center)
        infoWindow = new google.maps.InfoWindow()
        gMapContext.addListener("click", (event) => {
          console.log("google map tap", event.latLng.lat(), event.latLng.lng())
          // this.onMap(event)
          this.$emit("onmap")
        })
        gMapContext.addListener("center_changed", () => {
          this.onCenterChange()
          // console.log('center_changed')
        })
        gMapContext.addListener("zoom_changed", () => {
          this.onRegionChange()
          // console.log('zoom_changed')
        })
        this.onLocation()
      } else {
        this.times++
        console.log("gmaptimeout")
        gMapTimer = setTimeout(this.initMap, 500 * this.times)
      }
    },
    onCenterChange(e) {
      if (!gMapContext) {
        return
      }
      this.centerChange = true
      // console.log([gMapContext.getCenter().lat(), gMapContext.getCenter().lng()])
      this.$emit("center", [
        gMapContext.getCenter().lat(),
        gMapContext.getCenter().lng()
      ])
      this.$emit("setItem", null)
      setTimeout(() => {
        this.centerChange = false
      }, 100)
    },
    onRegionChange(e) {
      if (!gMapContext) {
        return
      }
      console.log(gMapContext.getZoom())
      const zoomLevel = (gMapContext.getZoom() / 2 - 2).toFixed(0)
      this.$emit("zoom", zoomLevel > 4 ? zoomLevel : 4)
    },
    onLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            // console.log(pos)
            gMapContext.setCenter(pos)
            this.setMarker(pos)
            this.latitude = position.coords.latitude
            this.longitude = position.coords.longitude
            this.$emit("center", [
              position.coords.latitude,
              position.coords.longitude
            ])
            // this.$emit('center', [22.631123, 113.820622])
          },
          (err) => {
            console.log(err)
            this.$emit("center", this.latLng)
            // this.$confirm({
            //   title: this.i18n.msg_title,
            //   content: 'Error: The Geolocation service failed.',
            //   showCancel: false
            // }).then(() => { })
          }
        )
      } else {
        // this.$toast(this.i18n.no_support)
      }
    },
    setMarker(pos) {
      if (!gMapContext) {
        return
      }
      const imgUrl = `${process.env.VUE_APP_DOMAIN}/content/marker.png`
      const image = {
        // url: require("@/assets/imgs/distance.png"),
        url: imgUrl,
        scaledSize: new google.maps.Size(25, 36),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(10, 30)
      }
      if (Array.isArray(pos)) {
        pos.forEach(([position, item], i) => {
          const markers = new google.maps.Marker({
            position,
            map: gMapContext,
            optimized: false,
            icon: image
            // zIndex: i + 1
          })
          // Add a click listener for each marker, and set up the info window.
          markers.addListener("click", () => {
            // console.log('点击标记了', markers.getTitle())
            this.$emit("setItem", item)
          })
        })
      } else {
        myMarker = new google.maps.Marker({
          position: pos,
          map: gMapContext,
          label: this.i18n.my_position
        })
      }
    },
    importMap() {
      if (!gMapContext && !window.google) {
        console.log("gmap import")
        let language = "en"
        if (this.lang !== "en-US") {
          language = "zh-CN"
        }
        this.createScript(
          `https://maps.googleapis.com/maps/api/js?key=${this.apikey}&callback=initMap&language=${language}`
        )
      } else {
        console.log("gmap init")
        this.initMap()
      }
    },
    createScript(src) {
      let oscript = window.document.createElement("script")
      oscript.id = `g-map-script-${this.lang}`
      oscript.src = src
      oscript.onerror = (error) => {
        console.log("google map load fail", error)
      }
      let head =
        window.document.head ||
        window.document.getElementsByTagName("head")[0] ||
        window.document.documentElement
      head.insertBefore(oscript, head.firstChild)
    }
  },
  created() {
    window.initMap = this.initMap
    this.latitude = this.latLng[0]
    this.longitude = this.latLng[1]
  },
  mounted() {
    console.log("google map mounted")
    this.importMap()
  },
  beforeDestroy() {
    console.log("google map destroyed")
    if (gMapContext) {
      gMapContext = null
    }
    if (gMapTimer) {
      clearTimeout(gMapTimer)
    }
  }
}
</script>

<style lang="less" scoped>
#g-map {
  height: 100%;
  width: 100%;
}

.location {
  position: fixed;
  top: 96px;
  right: 40px;
  z-index: 1;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(269deg, #e3e829 0%, #398e3c 100%);
}

.location-icon {
  position: absolute;
  width: 36px;
  height: 58px;
  bottom: 50%;
  left: calc(50% - 18px);
}
.van-image /deep/ .van-image__img {
  width: 100%;
}
</style>
